import React from "react";
import "./Header.css";
import toucanImage from "./resources/2can.png";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CodeIcon from "@material-ui/icons/Code";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import PhoneIcon from "@material-ui/icons/Phone";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

function Header() {
  return (
    <div className="header">
      <div className="header__container">
        <div className="header__menu header__option">
          <MenuIcon className="header__menuIcon header__optionIcon" />
        </div>
        <div className="header__title">
          <Link to="/" className="header__title__link">
            <img className="header__logo" src={toucanImage} alt="" />
            <h1>2can IT</h1>
          </Link>
        </div>
        <div className="header__nav">
          <Link to="/software" className="header__link">
            <div className="header__option">
              <CodeIcon className="header__optionIcon" />
              <span className="header__optionText">Oprogramowanie</span>
            </div>
          </Link>
          <Link to="/projects" className="header__link">
            <div className="header__option">
              <ArtTrackIcon className="header__optionIcon" />
              <span className="header__optionText">Realizacje</span>
            </div>
          </Link>
          <Link to="/customers" className="header__link">
            <div className="header__option">
              <SupervisorAccountIcon className="header__optionIcon" />
              <span className="header__optionText">Klienci</span>
            </div>
          </Link>
          <Link to="/" className="header__link">
            <div className="header__option">
              <EmojiPeopleIcon className="header__optionIcon" />
              <span className="header__optionText">Partnerzy</span>
            </div>
          </Link>
          <Link to="/contact" className="header__link">
            <div className="header__option">
              <PhoneIcon className="header__optionIcon" />
              <span className="header__optionText">Kontakt</span>
            </div>
          </Link>
        </div>
        <div className="header__social">
          <a href="/" target="_blank" rel="noreferrer">
            <FacebookIcon className="header__socialItem" />
          </a>
          <a href="/" target="_blank" rel="noreferrer">
            <InstagramIcon className="header__socialItem" />
          </a>
          <a
            href="https://www.linkedin.com/company/toucan-it/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedInIcon className="header__socialItem" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
