import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Header from "./Header";
import Home from "./Home";
import Contact from "./Contact";
import Projects from "./Projects";
import Customers from "./Customers";
import Software from "./Software";

function App() {
  return (
    <Router>
      <div className="app">
        <Header />
        <Switch>
          <Route path="/software">
            <Software className="app__page" />
          </Route>
          <Route path="/contact">
            <Contact className="app__page" />
          </Route>
          <Route path="/projects">
            <Projects className="app__page" />
          </Route>
          <Route path="/customers">
            <Customers className="app__page" />
          </Route>
          <Route path="/">
            <Home className="app__page" />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
