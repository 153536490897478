import React from "react";
import "./Customer.css";

function Customer({ logo, details }) {
  return (
    <div className="customer">
      <div className="customer__imageContainer">
        <img className="customer__image" src={logo} alt="" />
      </div>
      <p className="customer__details">{details}</p>
    </div>
  );
}

export default Customer;
