import React from "react";
import "./Contact.css";

function Contact() {
  return (
    <div className="contact">
      <h1>Kontakt</h1>
      <h3>
        {`Mikołaj Andrzejewski
        
        Ul. Janiny Porazińskiej 6d
        81-593 Gdynia
        
        tel.: +48 727 923 848
        e-mail: `}{" "}
        <a href="mailto:2canit.pl@gmail.com">2canit.pl@gmail.com</a>
      </h3>
    </div>
  );
}

export default Contact;
