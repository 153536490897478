import React from "react";
import "./SoftwareItem.css";

function SoftwareItem({ images, details }) {
  let imagesDiv;
  if (images.length === 1)
    imagesDiv = (
      <div className="softwareItem__images">
        <img className=" softwareItem__image" src={images[0]} alt="" />
      </div>
    );
  else
    imagesDiv = (
      <div className="softwareItem__images">
        <img className="softwareItem__imageOne" src={images[0]} alt="" />
        <img className="softwareItem__imageTwo" src={images[1]} alt="" />
      </div>
    );

  return (
    <div className="softwareItem">
      {imagesDiv}
      <div className="softwareItem__details">
        <p>{details}</p>
      </div>
    </div>
  );
}

export default SoftwareItem;
