import React from "react";
import "./Software.css";
import SoftwareItem from "./SoftwareItem";

import EagleIcon from "./resources/softwareLogos/autodesk-eagle.png";

function Software() {
  return (
    <div className="software">
      <h1 className="software__title">Oprogramowanie</h1>
      <div className="software__container">
        <SoftwareItem
          images={[
            "https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fpngimg.com%2Fuploads%2Fletter_c%2Fletter_c_PNG22.png&f=1&nofb=1",
            "https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.freeiconspng.com%2Fuploads%2Fc--logo-icon-0.png&f=1&nofb=1",
          ]}
          details="Jedną z naszych obszarów działalności jest świadczenie usług oraz wytwarzanie oprogramowania wbudowanego (Embedded) w języku C/C++. Posiadamy doświadczenie oparte m.in. na przygotowaniu oprogramowania urządzenia DAC Hi-Fi, czy sterownika pomiarowego wykonanego dla firmy SAMSUNG. Więcej szczegółów w zakładce Realizajce"
        />
        <SoftwareItem
          images={[
            "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.evanzimm.com%2Fstatic%2Fimg%2Fnet-icon.png&f=1&nofb=1",
            "https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.freeiconspng.com%2Fuploads%2Fc-logo-icon-18.png&f=1&nofb=1",
          ]}
          details="Pracownicy firmy Toucan IT, dzięki współpracy m.in. z firmą IZOMET, posiadają również doświadczenie w tworzeniu desktopowych aplikacji w języku C# z wykorzystaniem technologi .NET."
        />
        <SoftwareItem
          images={[EagleIcon]}
          details="Firma Toucan IT może pochwalić się wieloma projektami PCB, które zostały wykonane przy pomocy oprogramowania Autodesk EAGLE. Są to m.in. obwody wchodzące w skład urządzenia DAC Hi-Fi, płyta główna sterownika pomiarowego wykonanego dla firmy SAMSUNG czy moduł magistrali AS-i dla przetwornicy częstotliwości."
        />
        <SoftwareItem
          images={[
            "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcloud.netlifyusercontent.com%2Fassets%2F344dbf88-fdf9-42bb-adb4-46f01eedd629%2F064fc70f-5df3-4333-b9d4-f6abe2f946de%2Freact-wp-app8.png&f=1&nofb=1",
          ]}
          details="W 2020 roku firma rozszerzyła swoją działalność również w stronę projektowania aplikacji internetowych przy wykorzystaniu biblioteki React."
        />
      </div>
    </div>
  );
}

export default Software;
