import React from "react";
import "./Projects.css";
import Project from "./Project";

import DACImage from "./resources/projects/DAC.jpg";
import SamsungImage from "./resources/projects/samsung.jpg";
import SamsungDeviceImage from "./resources/projects/samsung_device.jpg";
import SamsungVisImage from "./resources/projects/samsung_vis.png";

function Projects() {
  return (
    <div className="projects">
      <div className="projects__title">
        <h1>Zrealizowane projekty</h1>
      </div>
      <div className="projects__container">
        <Project
          title="DAC Hi-Fi"
          images={[DACImage]}
          details="Projekt oraz wykonanie płytek drukowanych i oprogramowania firmware dla urządzenia DAC klasy Hi-Fi"
        />
        <Project
          title="System pomiarowy Samsung"
          images={[SamsungImage, SamsungDeviceImage, SamsungVisImage]}
          details="Rozproszony system pomiaru temperatury działający w oparcoi o protokół przemysłowy Modbus TCP"
        />
      </div>
    </div>
  );
}

export default Projects;
