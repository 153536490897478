import React from "react";
import Customer from "./Customer";
import "./Customers.css";

import IzometLogo from "./resources/customers/izomet.jpg";

function Customers() {
  return (
    <div className="customers">
      <h1>Zaufali nam</h1>
      <div className="customers__container">
        <Customer
          logo={IzometLogo}
          details="Firma Izomet od 1998 roku zajmuje się kompleksowym wdrażaniem systemów
        informatycznych oraz systemów automatyki przemysłowej."
        />
      </div>
    </div>
  );
}

export default Customers;
