import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";

function Home() {
  return (
    <div className="home">
      <span>
        <h1>Witamy na stronie firmy Toucan IT.</h1>
        <h3>
          Świadczymy usługi w zakresie rozwoju oprogramowania oraz automatyki
          przemysłowej.
        </h3>
        <h4>
          Przez ponad 6 lat współpracy z największymi dostawcami rozwiązań,
          nasza załoga zebrała doświadczenie w wielu dziedzinach:
          <ul>
            <li>
              Automatyka przemysłowa:
              <ul>
                <li>
                  Wytwarzanie oprogramowania dla sterowników PLC Beckhoff oraz
                  Siemens
                </li>
                <li>Systemy SCADA i HMI</li>
                <li>Projektowanie oraz montaż szaf sterowniczych</li>
              </ul>
            </li>
            <li>
              Urządzenia wbudowane (embedded):
              <ul>
                <li>Projektowanie prototypów urządzeń elektronicznych</li>
                <li>Wytwarzanie oprogramowania dla mikrokontrolerów ARM</li>
                <li>Projektowanie układów FPGA</li>
              </ul>
            </li>
            <li>
              Oprogramowanie komputerowe:
              <ul>
                <li>Projektowanie aplikacji desktopowych .NET</li>
                <li>Obsługa baz danych SQL oraz noSQL</li>
                <li>Tworzenie stron internetowych w technologii React.js</li>
              </ul>
            </li>
          </ul>
          Więcej szczegółow z zakładce <Link to="software">Oprogramowanie</Link>{" "}
          oraz <Link to="projects">Realizacje</Link>
        </h4>
      </span>
    </div>
  );
}

export default Home;
