import React from "react";
import "./Project.css";

function Project({ title, images, details }) {
  return (
    <div className="project">
      <div className="project__images">
        {images.length === 1 && (
          <img className="project__image" src={images[0]} alt="" />
        )}
        {images.length > 1 && (
          <img className="project__imageOne" src={images[0]} alt="" />
        )}
        {images.length > 1 && (
          <img className="project__imageTwo" src={images[1]} alt="" />
        )}
      </div>
      <div className="project__details">
        <h1 className="project__title">{title}</h1>
        <p>{details}</p>
      </div>
    </div>

    // <div className="project">
    //   <h1 className="project__title">{title}</h1>
    //   <div className="project__imageContainer">
    //     <img className="project__image" src={image[0]} alt="" />
    //     <div className="project__imageBox">
    //       {images.map((image) => (
    //         <img className="project__imageGalleryItem" src={image} alt="" />
    //       ))}
    //     </div>
    //   </div>
    //   <div className="project__details">
    //     <span>{details}</span>
    //   </div>
    // </div>
  );
}

export default Project;
